import React from 'react';

function Header() {
    return (
        <header className="bg-primary bg-opacity-10">
            <nav className="border-b border-gray-300">
                <div className="container px-6 py-6 mx-auto items-center justify-between flex">
                    <div>
                        <a className="text-lg text-primary hover:text-opacity-80" href="#">PatientApp<span className="hidden md:inline">/dev</span></a>
                    </div>
                
                    <div>
                        <a href="#" className="text-gray-600 hover:text-primary text-sm md:text-base">Documentation</a>
                    </div>
                </div>
            </nav>

            <div className="flex items-center container mx-auto px-6 py-8 lg:h-[32rem]">
                <div className="max-w-4xl">
                    <h2 className="text-3xl leading-[1.2] font-medium text-gray-800 lg:leading-[1.2] lg:text-5xl">
                        Accelerating the development of patient engagement solutions
                    </h2>
                        
                    <p className="mt-6 text-lg leading-8 text-gray-600 md:text-2xl">
                        Patient app is the suite of blocks with features required for Digital Health, Wellness, Digital therapeutic and Medical Device companies.
                    </p>

                    <div className="flex flex-col mt-8 sm:space-x-4 space-y-3 sm:space-y-0 sm:flex-row">
                        <input placeholder="Email Address" type="text" className="px-6 bg-white sm:w-80 py-3 text-gray-700  rounded-md border border-transparent focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40" />

                        <button type="button" className="px-8 py-2 text-center text-white capitalize transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80">
                            Request Demo
                        </button>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
