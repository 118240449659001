import React from 'react';

function Footer() {
    return (
        <footer className="bg-primary">
            <div className="container flex flex-col items-center px-6 py-16 mx-auto md:justify-between md:flex-row">
                <div className="flex flex-col items-center md:items-start">
                    <svg className="h-8" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.54607 4.14488C7.5418 4.14488 8.53966 5.31099 8.53966 7.64321V11.9247C8.53966 12.2496 8.44104 12.5049 8.24378 12.6905C8.05813 12.8762 7.79706 12.969 7.46058 12.969C7.12409 12.969 6.85722 12.8762 6.65997 12.6905C6.46271 12.5049 6.36409 12.2496 6.36409 11.9247V7.64321C6.36409 7.02824 6.23645 6.58153 5.98119 6.30305C5.73752 6.01298 5.34882 5.86794 4.81508 5.86794C4.18851 5.86794 3.68378 6.06519 3.30088 6.45969C2.92958 6.8542 2.74393 7.38214 2.74393 8.04351V11.9247C2.74393 12.2496 2.64531 12.5049 2.44806 12.6905C2.2508 12.8762 1.98393 12.969 1.64744 12.969C1.31096 12.969 1.04408 12.8762 0.846833 12.6905C0.661184 12.5049 0.568359 12.2496 0.568359 11.9247V1.51679C0.568359 1.21511 0.666985 0.971447 0.864237 0.785798C1.07309 0.600149 1.33996 0.507324 1.66485 0.507324C1.98973 0.507324 2.2508 0.594347 2.44806 0.768393C2.64531 0.942439 2.74393 1.1803 2.74393 1.48198V5.53725C3.03401 5.08473 3.41691 4.74244 3.89264 4.51038C4.37996 4.26672 4.93111 4.14488 5.54607 4.14488Z" fill="white" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M14.6965 0.557296C15.1495 0.718018 15.3864 1.21549 15.2257 1.66844L11.3966 12.4593C11.2359 12.9122 10.7385 13.1491 10.2855 12.9884C9.83256 12.8277 9.59567 12.3302 9.75639 11.8773L13.5854 1.08641C13.7461 0.633467 14.2436 0.396574 14.6965 0.557296Z" fill="white" />
                    </svg>

                    <p className="mt-6 text-white">Product by hlth.dev</p>
                </div>

                <div className="flex flex-col w-full mt-8 space-y-3 lg:mt-0 sm:w-auto sm:space-x-4 sm:space-y-0 sm:flex-row">
                    <input placeholder="Enter Your Email" type="text" className="px-6 py-3 text-white bg-transparent border-2 border-gray-300 rounded-md sm:w-80 placeholder-slate-300 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40" />

                    <button className="px-8 py-2 text-center capitalize transition-colors duration-200 transform bg-white rounded-md text-primary hover:bg-opacity-80">
                        Join Waitlist
                    </button>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
